@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
  color: #333;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-slideshow-container button {
  display: none !important;
}

input, textarea {
  width: 300px;
  border: 2px solid black;
  padding: 8px;
  font-size: 16px;
  border-radius: 3px;
  font-family: 'Montserrat', sans-serif;
}

button {
  color: white;
  background-color: black;
  font-family: 'Montserrat', sans-serif;
  border: none;
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 16px;
}

.btn {
  background-color: #EAC840;
  color: white !important;
  padding: 8px 32px;
  border-radius: 8px;
  cursor: pointer;
}

.btn.big {
  padding: 16px 32px;
  font-size: 24px;
  border-radius: 32px;
  box-shadow: 0 0 8px rgba(0,0,0,.2);
}


h4 {
  font-size: 28px;
  font-weight: 800;
  margin: 0;
}

a {
  color: #333 !important;
  text-decoration: none;
  font-weight: bold;
}

.overlay{
  position: absolute;
  pointer-events: none;
  z-index: 2;
  top: 0;
  left: 0; 
  opacity: .15;
  display: block;
  background: -webkit-linear-gradient(45deg, #ccc 25%, transparent 25%, transparent), -webkit-linear-gradient(-45deg, #ccc 25%, transparent 25%, transparent), -webkit-linear-gradient(45deg, transparent 75%, #ccc 75%), -webkit-linear-gradient(-45deg, #333 75%, #ccc 75%);
  background-size: 2px 2px;

  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  input, textarea {
    width: 100%;
  }
}
